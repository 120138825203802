'use client'
import { types } from "@/types"
import { constants } from "@/utils/global"
import { useReducer } from "react"
import { CatalogueContext } from "./CatalogueContext"
import { catalogueReducer } from "./catalogueReducer"

const initialState = {
  more: false,
  modalFilter: false,
  loading: false,
  filters: [],
  pathFilter: [],
  children: [],
  info: {},
  breadCrumbs: [],
  id: null,
  category: null,
  cleanPath: ``,
  limit: constants.CATALOGO_LIMIT,
  execute: true,
}

export const CatalogueProvider = ({ children }) => {
  const [ state, dispatch ] = useReducer(catalogueReducer, initialState)

  const getInit = props => {
    dispatch({
      type: types.catalogue.init,
      payload: props
    })
  }
  const handleMore = () => {
    dispatch({ type: types.catalogue.more })
  }

  const handleModalFilter = () => {
    dispatch({ type: types.catalogue.modalFilter })
  }

  const handlePathFilter = props => {
    dispatch({
      type: types.catalogue.pathFilter,
      payload: props
    })
  }
  const handleExecute = props => {
    dispatch({
      type: types.catalogue.execute,
      payload: props
    })
  }
  const handleRestart = () => {
    dispatch({ type: types.catalogue.restart })
  }

  const handleLoading = props => {
    dispatch({
      type: types.catalogue.loading,
      payload: props
    })
  }

  return (
    <CatalogueContext.Provider
      value={{
        info: { ...state },
        getInit,
        handleMore,
        handlePathFilter,
        handleExecute,
        handleRestart,
        handleModalFilter,
        handleLoading
      }}
    >
      {children}
    </CatalogueContext.Provider>
  )
}
