'use client'
import { types } from "@/types"
import { constants } from "@/utils/global"
import { useReducer } from "react"
import { FiltersContext } from "./FiltersContext"
import { filtersReducer } from "./filtersReducer"

const initialState = {}

export const FiltersProvider = ({ children }) => {
  const [ state, dispatch ] = useReducer(filtersReducer, initialState)

  const getFilterInit = props => {
    dispatch({
      type: types.catalogue.init,
      payload: props
    })
  }

  return (
    <FiltersContext.Provider
      value={{
        fOpen: { ...state },
        getFilterInit,
      }}
    >
      {children}
    </FiltersContext.Provider>
  )
}
