'use client'
import { types } from "@/types"
import { useEffect, useReducer } from "react"
import { PatherContext, patherReducer } from "."

const initialState = []

export const PatherProvider = ({ children, phater }) => {
  const [ paths, dispatch ] = useReducer(patherReducer, initialState)
  const handleInitPath = () => {
    dispatch({
      type: types.path.init,
      payload: phater
    })
  }
  useEffect(() => {
    !!phater && handleInitPath()
  }, [phater])
  return (
    <PatherContext.Provider
      value={{
        paths,
      }}
    >
      {children}
    </PatherContext.Provider>
  )
}
