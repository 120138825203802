export const initObj = {
  firstCharge: false,
  addressList: [],
  address: null,
  insurance: null,
  sede: null, // sede
  model: null, // modelo de tienda murcia o granada
  payMethod: null, // metodo de pago seleccionado
  dpm: null, // metodo de pago en uso
  type: 1, // tipo de pago
  checked: false, // check seguro o cambio de talla
  openBasket: true, // flag cesta de productos
  openInvAddr: false, // flag dirección de facturación
  openShipAddr: true, // flag empresa de transporte
  openShipServs: true, // flag empresa de transporte
  shipServs: [], // Lista de empresas de transporte
  selShipServs: null, // Empresa de transporte seleccionada
  pdrsList: [], // Lista de puntos de recogida
  openPickUps: false, // flag puntos de recogida
  openPay: false, // flag metodos de pago
  pickupSelected: null, // se ha seleccionado pickup
  selPickup: null, // se ha confirmado pickup
  shipPickups: [], // lista de empresas conpunto de recogida
  dbPdrId: null, // id de punto de recogida generado
  pickupModal: false,
}
