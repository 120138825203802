'use client'
import { types } from "@/types"
import { useReducer } from "react"
import { CookiesContext } from "./CookiesContext"
import { CookiesReducer } from "./cookiesReducer"

const initialState = {
  [types.cookies.preference.preferences]: false,
  [types.cookies.msg.preferences]: true,
}

export const CookiesProvider = ({ children }) => {
  const [ state, dispatch ] = useReducer(CookiesReducer, initialState)

  const toggleCookiePreferences = () => {
    dispatch({ type: types.cookies.preference.cookie })
  }
  const toggleViewMsgCookie = () => {
    dispatch({ type: types.cookies.msg.cookie })
  }

  return (
    <CookiesContext.Provider
      value={{
        ...state,
        toggleCookiePreferences,
        toggleViewMsgCookie,
      }}
    >
      {children}
    </CookiesContext.Provider>
  )
}
