'use client'
import { useMenu } from "@/hooks/menu"
import { config } from "@/middleware/config/config"
import { types } from "@/types"
import { getCookie, hasCookie } from "cookies-next"
import { useEffect, useReducer, useState } from "react"
import { MenuContext } from "./MenuContext"
import { menuInitialState, menuReducer } from "./menuReducer"

export const MenuProvider = ({ children, ...prop }) => {
  const { data, hostname } = prop

  const [ cookies, setCookies ] = useState(undefined)
  const { menu, callMenu } = useMenu({ level: 1, father: null, events: null, abbrElastic: data?.hostname?.business.abbrElastic || hostname?.business.abbrElastic })
  const [ state, dispatch ] = useReducer(menuReducer, menuInitialState)

  const toggleMenu = () => {
    dispatch({ type: types.menuExpanded.openMenu })
  }
  const toggleMenuAuto = props => {
    dispatch({ type: types.menuExpanded.openMenu, payload: props })
  }
  const handlelevel1 = props => {
    dispatch({ type: types.menuExpanded.level1, payload: props })
  }
  const handlelevel2 = props => {
    dispatch({ type: types.menuExpanded.level2, payload: props })
  }
  const handlelevel3 = props => {
    dispatch({ type: types.menuExpanded.level3, payload: props })
  }

  const toggleExpanded = async props => {
    await handlelevel2([])

    props !== false && await handlelevel2(await callMenu({ level: 2, father: props, abbrElastic: data?.hostname?.business.abbrElastic || hostname.business.abbrElastic }))
    await dispatch({ type: types.menuExpanded.expanded, payload: props })
  }
  const toggleExpandedCategory = async props => {
    await handlelevel3([])
    props !== false && await handlelevel3(await callMenu({ level: 3, father: props, abbrElastic: data?.hostname?.business.abbrElastic || hostname.business.abbrElastic }))
    dispatch({ type: types.menuExpanded.expandedCategory, payload: props })
  }
  const toggleExpandedSubCategory = props => {
    dispatch({ type: types.menuExpanded.expandedSubCategory, payload: props })
  }

  useEffect(() => {
    state[types.menuExpanded.level1].length === 0 && handlelevel1(menu)
  }, [menu])
  useEffect(() => {
    cookies === undefined && hasCookie(config.COOKIES._WLYR_) && setCookies(JSON.parse(getCookie(config.COOKIES._WLYR_)))
  })

  // useEffect(() => {
  //   const tmpHost = hasCookie(config.COOKIES._WLYR_) && JSON?.parse(getCookie(config.COOKIES._WLYR_))
  //   tmpHost?.events && !!hostname && callMenu({ level: 1, father: null, events: tmpHost.events || hostname?.events , abbrElastic: hostname?.business.abbrElastic })
  //   return  handlelevel1(menu)
  // }, [cookies])
  useEffect(() => {
    !!hostname && callMenu({ level: 1, father: null, events: hostname.events, abbrElastic: hostname?.business.abbrElastic })
    return handlelevel1(menu)
  }, [hostname])

  return (
    <MenuContext.Provider
      value={{
        ...state,
        toggleMenu,
        toggleMenuAuto,
        toggleExpanded,
        toggleExpandedCategory,
        toggleExpandedSubCategory,
      }}
    >
      {children}
    </MenuContext.Provider>
  )
}
