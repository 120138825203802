/* eslint-disable default-param-last */
import { types } from "@/types"
const initialState = []
export const patherReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.path.init:
    return action.payload

    // case `example`:
    //  return {
    //    ...state,
    //    [action.payload.name]: action.payload.flag,
    //  }

  default:
    return state
  }
}
