import { apiCall } from "@/middleware/api"
import { encodeHashJS } from "@/middleware/api/secure"
import { types } from "@/types"
import { constants } from "@/utils/global"
import { btoa } from "js-base64"

export const getIpClient = async() => {
  const res = await fetch(`https://api.ipify.org?format=json`, { cache: `force-cache`, next: { revalidate: 3600 }}).then(resp => resp.json())
  const ip = await res.ip

  return ip
}
export const listCardsClient = async({
  payMethod, userId, setResponse, ...props
}) => {
  const encrypt = {
    user_id: userId,
    pay_method_id: payMethod
  }
  const sendData = {
    url: `${constants.URL.CART_PAYMETHODS}${types.cards.list}`,
    encrypt,
    type: `POST`
  }
  const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
  const { error, message } = await apiCall(encriptedData)
  await setResponse(message?.list?.filter(x => x.pan !== `0`))
}

export const removeCardsClient = async({
  cardId, payMethod, userId, ...props
}) => {
  const encrypt = {
    card_id: cardId,
    user_id: userId,
    pay_method_id: payMethod
  }
  const sendData = {
    url: `${constants.URL.CART_PAYMETHODS}${types.cards.remove}`,
    encrypt,
    type: `POST`
  }
  const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
  const { error, message } = await apiCall(encriptedData)
  return message
}

export const payNewCard = async({
  payMethod, saleOrder, userId, language, setResponse, ...props
}) => {
  const encrypt = {
    user_id: userId,
    pay_method_id: payMethod,
    sale_order_id: saleOrder,
    urlOk: `${window.location.origin}${process.env.NEXT_PUBLIC_PAYMENT_OK}?o=${btoa(saleOrder)}`,
    urlKo: `${window.location.origin}${process.env.NEXT_PUBLIC_PAYMENT_ERROR}?psearch=true`,
  }
  const sendData = {
    url: `${constants.URL.CART_PAYMETHODS}${types.cards.new}/${language.toUpperCase()}`,
    encrypt,
    type: `POST`,
  }
  const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
  const { error, message } = await apiCall(encriptedData)
  await setResponse(a => message.redirect)
}

export const payOtherCard = async({
  payMethod, saleOrder, language, cardId, ...props
}) => {
  const ip = await getIpClient()
  const encrypt = {
    card_id: cardId,
    pay_method_id: payMethod,
    sale_order_id: saleOrder,
    ip,
    urlOk: `${window.location.origin}${process.env.NEXT_PUBLIC_PAYMENT_OK}?o=${btoa(saleOrder)}`,
    urlKo: `${window.location.origin}${process.env.NEXT_PUBLIC_PAYMENT_ERROR}`,
  }
  const sendData = {
    url: `${constants.URL.CART_PAYMETHODS}${types.cards.other}/${language.toUpperCase()}`,
    encrypt,
    type: `POST`,
  }
  const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
  const { error, message } = await apiCall(encriptedData)

  return {
    error,
    message
  }
}
