'use client'
import { types } from "@/types"
import { usePathname } from "next/navigation"
import { useEffect, useReducer } from "react"
import { BasketContext, basketReducer } from "."

const basketInit = {
  basket: false,
  menu: false,
}

export const BasketProvider = ({ children }) => {
  const [ state, dispatch ] = useReducer(basketReducer, basketInit)
  const pathname = usePathname()
  const handleShowBasket = prop => {
    dispatch({
      type: types.basket.showBasket,
      payload: prop
    })
  }
  const handleShowMenu = prop => {
    dispatch({
      type: types.basket.showMenu,
      payload: prop
    })
  }
  const handleStart = props => {
    dispatch({ type: types.basket.init })
  }
  useEffect(() => {
    handleStart()
  }, [pathname])
  return (
    <BasketContext.Provider
      value={{
        show: { ...state },
        handleShowBasket,
        handleShowMenu,
      }}
    >
      {children}
    </BasketContext.Provider>
  )
}
