export const initObj = {
  discount: ``,
  item: [],
  selected: 0,
  variants: [],
  unit: 1,
  price: {},
  totalPrice: 0,
  size: ``,
  toReceive: ``,
  shipCost: 0,
  breadCrumbs: [],
  parentCategory: null,
  categoryName: null,
  category: null,
  comment: {},
  canonical: [],
  similar: [],
  related: [],
  consumable: [],
  ipInfo: {},
}
