'use client'
/* eslint-disable no-extra-parens */
/* eslint-disable prefer-destructuring */
import { types } from "@/types"
import { fixTwoDecimal } from "@/utils/functions"
import { useEffect, useMemo, useReducer, useState } from "react"
import { initObj } from "./Obj"
import { PanelBuyContext } from "./PanelBuyContext"
import { panelBuyReducer } from "./panelBuyReducer"
import { useEffectOnce } from "@/hooks/utils/useEffectOnce"

export const PanelBuyProvider = props => {
  const { children, hostname, product, bike, variants } = props
  const { texts: { globalTexts }} = hostname
  const { variant } = product
  const [ obj, setObj ] = useState()
  const [ state, dispatch ] = useReducer(panelBuyReducer, initObj)
  const tmpVariant = useMemo(() => variant.map(x => {
    if (product.pack === null || product.lote) {
      const type = product.pack === null && !product.lote ? `price` : `pack`
      const item = variants?.find(b => b.variant_id === x.variant_id)
      const price = product.lote ? item.pack : item?.price || x.price
      return {
        ...x,
        [type]: price,
        external: item?.external || x.external,
        web_availability: item?.web_availability || x.web_availability,
        sale_ko: item?.sale_ko || x.sale_ko,
        lote: product.lote
      }
    }
    return { ...x }
  }), [variants])
  const handleInit = prop => {
    dispatch({ type: types.panelBuy.init, payload: prop })
  }
  const handleSetItem = prop => {
    dispatch({ type: types.panelBuy.setItem, payload: prop })
  }
  const handleSetUnit = prop => {
    dispatch({ type: types.panelBuy.unit, payload: prop })
  }

  useEffectOnce(() => {
    (async() => {
      let price
      const itemObj = {
        discount: 0,
        item: [],
        selected: 0,
      }
      if (!bike) {
        price = product.pack === null && tmpVariant[0].price !== null ? tmpVariant[0].price : product.pack
        const flagProd = product.pack === null && !product.lote && tmpVariant.length === 1
        if (flagProd) {
          const temp = await tmpVariant.sort((a, b) => {
            if (a.price.cost > b.price.cost) {
              return 1
            }
            if (a.price.cost < b.price.cost) {
              return -1
            }
            return 0
          })
          price = temp[0].price !== undefined ? temp[0].price : product.pack
        } else if (product.lote) {
          price =
          {
            pvp: tmpVariant.reduce((sum, item) => sum + (item.pack.pvp * item.pack.amount || 0), 0),
            cost: tmpVariant.reduce((sum, item) => sum + (item.pack.price * item.pack.amount || 0), 0)
          }
          const discount = price.pvp > 0 ? fixTwoDecimal(100 - (price.cost / price.pvp * 100)) : 0
          price.discount = discount
        }
        itemObj.discount = `${globalTexts.discount} ${fixTwoDecimal(Math.abs(price?.discountIVA || price?.discount), 0)}${globalTexts.percent}`
        itemObj.item = flagProd ? [tmpVariant[0]] : product.pack !== null ? tmpVariant : []
        itemObj.selected = flagProd ? tmpVariant[0].variant_id : 0
      } else {
        price = product.price
      }
      const objs = {
        ...initObj,
        breadCrumbs: product.breadcrumbs,
        parentCategory: product.breadcrumbs[0].id,
        categoryName: product.breadcrumbs[1]?.h1 || ``,
        category: product.breadcrumbs[1]?.id || null,
        variants: tmpVariant,
        price,
        ipInfo: hostname.ipInfo,
        ...itemObj,
      }
      await setObj(objs)
    })()
  })
  useEffect(() => {
    !!obj && handleInit({ ...obj })
  }, [obj])

  return (
    <PanelBuyContext.Provider
      value={{
        productBuy: { ...state },
        handleInit,
        handleSetItem,
        handleSetUnit,
      }}
    >
      {children}
    </PanelBuyContext.Provider>
  )
}
