'use client'
import { types } from "@/types"
import { usePathname } from "next/navigation"
import { useEffect, useReducer } from "react"
import { MobileContext } from "./MobileContext"
import { mobileMenuInitialState, mobileReducer } from "./mobileReducer"

export const MobileProvider = ({ children }) => {
  const pathname = usePathname()
  const [ state, dispatch ] = useReducer(mobileReducer, mobileMenuInitialState)

  const toggleOpenSwip = () => {
    dispatch({ type: types.mobileMenu.openMenu })
  }
  const toggleOpenSwipMobile = payload => {
    dispatch({ type: types.mobileMenu.openMenuMobile, payload })
  }
  const toggleIndex = prop => {
    dispatch({
      type: types.mobileMenu.index,
      payload: prop
    })
  }
  const toggleCategory = prop => {
    dispatch({
      type: types.mobileMenu.category,
      payload: prop
    })
  }

  const toggleOpenCategory = prop => {
    dispatch({
      type: types.mobileMenu.openCategory,
      payload: prop
    })
  }
  useEffect(() => {
    pathname !== process.env.NEXT_PUBLIC_HOME &&
      [
        process.env.NEXT_PUBLIC_STORE,
        process.env.NEXT_PUBLIC_SUPPORT,
        process.env.NEXT_PUBLIC_CONTACT,
        process.env.NEXT_PUBLIC_ABOUT,
      ].includes(pathname) &&
      toggleOpenSwip(false)
  }, [pathname])
  return (
    <MobileContext.Provider
      value={{
        ...state,
        toggleOpenSwip,
        toggleOpenSwipMobile,
        toggleIndex,
        toggleCategory,
        toggleOpenCategory,
      }}
    >
      {children}
    </MobileContext.Provider>
  )
}
