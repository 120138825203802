/**
 * Button base component with customizable styles and types.
 *
 * @param {Object} props - The properties object.
 * @param {React.ReactNode} props.children - The content to be displayed inside the button.
 * @param {string} [props.className] - Additional class names for the button.
 * @param {Object} [props.style] - Inline styles for the button.
 * @param {Object} props.colors - Object containing color values.
 * @param {string} [props.color] - Text color of the button.
 * @param {string} [props.bg] - Background color of the button.
 * @param {string} [props.hvr] - Hover color of the button.
 * @param {string} [props.border] - Border color of the button.
 * @param {string} [props.type='base'] - Type of the button, can be 'base', 'outlined', 'underlined', or 'dropdown'.
 * @param {React.Ref} ref - The ref to be forwarded to the button element.
 */
import { forwardRef } from 'react'

export const TWButton = forwardRef(({
  children, className = ``, style, colors, color, bg, hvr, border, type = `base`, animated = undefined, small = undefined, ...props
}, ref) => {
  const { disabled } = props
  const obj = {
    base: {
      btnType: `btn-base`,
      color: disabled ? colors.lightGrey : color ?? colors.white,
      border: border || colors.lightGrey,
      bgColor: disabled ? colors.lightGrey : bg ?? colors.main,
      animated: animated ?? false
    },
    outlined: {
      btnType: `btn-outlined`,
      color: disabled ? colors.lightGrey : color ?? colors.main,
      border: border || colors.lightGrey,
      bgColor: disabled ? colors.lightGrey : bg ?? colors.main,
      animated: animated ?? false
    },
    underlined: {
      btnType: `btn-underlined`,
      color: disabled ? colors.lightGrey : color ?? colors.main,
      border: border || colors.lightGrey,
      bgColor: disabled ? colors.lightGrey : bg ?? colors.main,
      animated: animated ?? false
    },
    dropdown: {
      btnType: `btn-dropdown`,
      color: disabled ? colors.lightGrey : color ?? colors.main,
      border: border || colors.lightGrey,
      bgColor: disabled ? colors.lightGrey : bg ?? `transparent`,
      animated: animated ?? false
    },
    text: {
      btnType: `btn-text`,
      color: disabled ? colors.lightGrey : color ?? colors.dark,
      border: `transparent`,
      bgColor: `transparent`,
      animated: animated ?? false
    }
  }

  return (
    <button {...props} ref={ref}
      className={`${className} ${obj[type].btnType} ${disabled ? `cursor-not-allowed` : `cursor-pointer`}`}
      style={{
        ...style,
        "--font": small ? `0.875rem` : `1rem`,
        "--lineH": `1rem`,
        "--padding": small ? `0.3rem 0.8rem` : `0.5rem 0.8rem`,
        "--animated": obj[type].animated ? `scale(0.98)` : `none`,
        "--hvr": disabled ? colors.lightGrey : hvr ?? colors.secondary, "--bg": obj[type].bgColor, "--color": obj[type].color, '--border': obj[type].border
      }}>
      {children}
    </button>
  )
})
