/* eslint-disable default-param-last */
import { types } from "@/types"
import { constants } from "@/utils/global"
const initialState = {
  more: false,
  modalFilter: false,
  loading: false,
  filters: [],
  pathFilter: [],
  children: [],
  info: {},
  breadCrumbs: [],
  id: null,
  category: null,
  cleanPath: ``,
  limit: constants.CATALOGO_LIMIT,
  execute: true,
}
export const catalogueReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.catalogue.init:
    return {
      ...state,
      filters: action.payload.filters,
      pathFilter: action.payload.pathFilter,
      children: action.payload.children,
      info: action.payload.info,
      breadCrumbs: action.payload.breadCrumbs,
      id: action.payload.id,
      category: action.payload.category,
      cleanPath: action.payload.cleanPath,
    }
  case types.catalogue.more:
    return {
      ...state,
      more: !state.more,
    }
  case types.catalogue.loading:
    return {
      ...state,
      loading: action.payload,
    }
  case types.catalogue.modalFilter:
    return {
      ...state,
      modalFilter: !state.modalFilter,
    }
  case types.catalogue.pathFilter:
    return {
      ...state,
      pathFilter: action.payload,
    }
  case types.catalogue.execute:
    return {
      ...state,
      execute: action.payload.execute,
    }
  case types.catalogue.restart:
    return {
      ...initialState,
    }
  default:
    return state
  }
}
