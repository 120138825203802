'use client'
import { types } from "@/types"
import { constants } from "@/utils/global"
import { useReducer } from "react"
import { ProductsContext, productsReducer } from "."

const initialState = {
  maxPrice: constants.PRICE_RANGE.max,
  result: 0,
  urlResults: 0,
  attr: [],
  catalogue: [],
  brandAttr: [],
  categories: [],
  filters: [],
  labels: [],
}

export const ProductsProvider = ({ children }) => {
  const [ state, dispatch ] = useReducer(productsReducer, initialState)

  const handleInitList = props => {
    dispatch({
      type: types.products.init,
      payload: props
    })
  }
  const handleSearchList = props => {
    dispatch({
      type: types.products.list,
      payload: props
    })
  }
  const handleRestartList = () => {
    dispatch({ type: types.products.restart })
  }

  return (
    <ProductsContext.Provider
      value={{
        prodList: { ...state },
        handleInitList,
        handleSearchList,
        handleRestartList,
      }}
    >
      {children}
    </ProductsContext.Provider>
  )
}
