/* eslint-disable default-param-last */
import { types } from "@/types"
import { fixTwoDecimal } from "@/utils/functions"
import { initObj } from "./Obj"

export const panelBuyReducer = (state = initObj, action) => {
  switch (action.type) {
  case types.panelBuy.restart:
    return initObj
  case types.panelBuy.setItem:
    return {
      ...state,
      ...action.payload,
      totalPrice: fixTwoDecimal((action.payload?.price?.cost || action.payload?.pack?.cost) * state.unit),
    }
  case types.panelBuy.unit:
    return {
      ...state,
      ...action.payload,
      totalPrice: fixTwoDecimal(state.price.cost * action.payload.unit),
    }
  case types.panelBuy.init:
    return {
      ...state,
      ...action.payload,
      totalPrice: fixTwoDecimal((action.payload?.price?.cost || action.payload?.pack?.cost) * state.unit),
    }
  case types.panelBuy.comment:
    return {
      ...state,
      comment: action.payload,
    }
  default:
    return state
  }
}
