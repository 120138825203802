/* eslint-disable default-param-last */
import { types } from "@/types"
export const mobileMenuInitialState = {
  [types.mobileMenu.openMenu]: false,
  [types.mobileMenu.openMenuMobile]: false,
  [types.mobileMenu.index]: 0,
  [types.mobileMenu.category]: {},
  [types.mobileMenu.openCategory]: false,
}
export const mobileReducer = (state = mobileMenuInitialState, action) => {
  switch (action.type) {
  case types.mobileMenu.openMenu:
    return {
      ...state,
      [types.mobileMenu.openMenu]: !state[types.mobileMenu.openMenu],
    }
  case types.mobileMenu.openMenuMobile:
    return {
      ...state,
      [types.mobileMenu.openMenuMobile]: action.payload,
    }
  case types.mobileMenu.index:
    return {
      ...state,
      [types.mobileMenu.index]: action.payload,
    }
  case types.mobileMenu.category:
    return {
      ...state,
      [types.mobileMenu.category]: action.payload,
    }
  case types.mobileMenu.openCategory:
    return {
      ...state,
      [types.mobileMenu.openCategory]: action.payload,
    }
  default:
    return state
  }
}
