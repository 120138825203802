/* eslint-disable default-param-last */
import { types } from "@/types"
const initialState = {
  [types.cookies.preference.preferences]: false,
  [types.cookies.msg.preferences]: true,
}
export const CookiesReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.cookies.preference.cookie:
    return {
      ...state,
      [types.cookies.preference.preferences]: !state[types.cookies.preference.preferences],
    }
  case types.cookies.msg.cookie:
    return {
      ...state,
      [types.cookies.msg.preferences]: !state[types.cookies.msg.preferences],
    }

  default:
    return state
  }
}
