/* eslint-disable default-param-last */
import { types } from "@/types"
const initialState = {}
export const filtersReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.catalogue.init:
    return {
      ...state,
      [action.payload.name]: action.payload.flag,
    }

  default:
    return state
  }
}
