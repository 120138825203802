/* eslint-disable default-param-last */
import { types } from "@/types"
const image = []
export const imagesReducer = (state = image, action) => {
  switch (action.type) {
  case types.product.img:
    return action.payload

  default:
    return state
  }
}
