import { domainComplet } from "@/utils/functions"
import { useState } from "react"

export const useMenu = () => {
  const [ menu, setMenu ] = useState([])

  const callMenu = async props => {
    const { id, platformId } = await domainComplet()
    const body = {
      level: props.level,
      platform: platformId,
      language: id,
      father: props.father,
      events: props.events,
      abbrElastic: props.abbrElastic
    }

    const { code, result } = await fetch(`/api/menu`, {
      // next: { revalidate: 3600 },
      cache: `no-store`,
      method: `POST`,
      body: JSON.stringify(body),
      headers: {
        'Content-Type': `application/json`
      }
    }).then(res => res.json())
    menu?.length === 0 && await setMenu(x => result)
    return result
  }

  return {
    menu,
    callMenu,
  }
}
