'use client'
/* eslint-disable no-extra-parens */
import { useCart } from "@/hooks/cart/useCart"
import { config } from "@/middleware/config/config"
import { types } from "@/types"
import { fixTwoDecimalNotFixed } from "@/utils/functions"
import { saleOrderSession } from "@/utils/functions/cart"
import { hasCookie } from "cookies-next"
import Cookies from "js-cookie"
import { useSession } from "next-auth/react"
import {
  useContext,
  useEffect, useMemo, useReducer, useState
} from "react"
import { CartContext } from "./CartContext"
import { cartReducer } from "./cartReducer"
import { ToolsContext } from "../tools/tools"

const cartState = {
  saleOrderId: null,
  basket: {},
  confirm: {},
  checkout: null,
  deliveryDate: null,
  paypal: {
    noFeeTotal: 0,
    feeAmount: 0,
    feePerc: 0,
  },
  insurance: 0,
  coupon: [],
  currencies: [],
  currency: {
    id: 1,
    symbol: `€`,
    code: `EUR`,
    translate: {
      es: `Euro`,
      fr: `Euro`,
      de: `Euro`,
      pt: `Euro`,
      en: `Euro`,
      it: `Euro`
    },
    exchange: 1
  },
  cartDrawerOpen: false
}

export const CartProvider = ({ children, hostname, ipInfo }) => {
  const [ stateCart, dispatch ] = useReducer(cartReducer, cartState)
  const { tools: { currencies }} = useContext(ToolsContext)
  const session = useSession()
  const { recoveryCart } = useCart(hostname)
  const [ feeAmount, setFeeAmount ] = useState(0)
  const [ feePerc, setFeePerc ] = useState(0)

  const flagTotal = useMemo(() => !!stateCart.basket.total_price, [stateCart.basket.total_price])
  const setSaleOrder = props => {
    dispatch({
      type: types.initCart,
      payload: props
    })
  }
  const handlePaypal = props => {
    dispatch({
      type: types.cart.paypal,
      payload: props
    })
  }
  const handleBasket = props => {
    dispatch({
      type: types.cartRemoveVariant,
      payload: props
    })
  }
  const handleConfirm = props => {
    dispatch({
      type: types.confirmCart,
      payload: props
    })
  }
  const handleIpInfo = props => {
    dispatch({
      type: types.cart.ipinfo,
      payload: props
    })
  }
  const handleCartInsurance = props => {
    dispatch({
      type: types.cart.insurance,
      payload: props
    })
  }
  const handleCartCoupon = props => {
    dispatch({
      type: types.cart.cartCoupon,
      payload: props
    })
  }
  const handleCurrencies = props => {
    dispatch({
      type: types.cart.currencies,
      payload: props
    })
  }
  const handleCurrency = props => {
    dispatch({
      type: types.cart.currency,
      payload: props
    })
  }
  const handleDeliveryDate = props => {
    dispatch({
      type: types.cart.deliveryDate,
      payload: props
    })
  }
  const toggleCartDrawer = props => {
    dispatch({
      type: types.cart.cartDrawer,
      payload: props
    })
  }

  const handleRestartCart = () => {
    dispatch({ type: types.cart.restart })
  }

  const handleCart = (
    id, confirm = false, host = null, ip = null
  ) => {
    if (id || (hostname !== null && stateCart.saleOrderId !== null && stateCart.saleOrderId !== `null` && stateCart.basket.id === undefined)) {
      recoveryCart({
        sale_order_id: id || stateCart.saleOrderId,
        user_id: session.data?.user?.user_id || null,
        language: hostname?.lang,
        discount_tariff: confirm ? host.discount_tariff_version : hostname?.discount_tariff_version,
        country: confirm ? ip?.code : ipInfo.code,
      },
      confirm || !session.data?.user,
      confirm ? handleConfirm : handleBasket)
    }
  }
  useEffect(() => {
    handleCurrencies(currencies)
  }, [currencies])
  useEffect(() => {
    hostname !== null && saleOrderSession(session, handleCart)
  }, [hostname])
  useEffect(() => {
    handleIpInfo(ipInfo)
  }, [ipInfo])
  useEffect(() => {
    flagTotal && setFeeAmount(fixTwoDecimalNotFixed(((stateCart.basket.total_price || 0) + 0.35) * 0.037 + 0.35))
  }, [stateCart.basket.total_price])

  useEffect(() => {
    flagTotal && setFeePerc(fixTwoDecimalNotFixed((feeAmount * 100) / (stateCart.basket.total_price || 0)))
  }, [feeAmount])

  useEffect(() => {
    flagTotal &&
      handlePaypal({
        noFeeTotal: stateCart.basket.total_price || 0,
        feeAmount,
        feePerc,
      })
  }, [feePerc])

  useEffect(() => {
    const has = hasCookie(config.COOKIES._WLYR_ORDER)
    if (has && stateCart.saleOrderId === null) {
      const order = Cookies.get(config.COOKIES._WLYR_ORDER)
      const tmp = JSON.parse(order).saleOrder
      setSaleOrder(tmp)
    }
  }, [])

  useEffect(() => {
    handleCart()
  }, [ stateCart.saleOrderId, hostname ])

  useEffect(() => {
    if (stateCart.currencies?.length > 0) {
      handleCurrency(stateCart.currencies.find(x => hostname?.id === 16 ? x.default === hostname?.domain : x.id === 1))
    }
  }, [ stateCart.currencies, hostname ])

  useEffect(() => {
    !stateCart.deliveryDate && !!stateCart.basket?.deliveryDate && handleDeliveryDate(stateCart.basket.deliveryDate)
  }, [stateCart.basket?.deliveryDate])

  return (
    <CartContext.Provider
      value={{
        cart: { ...stateCart },
        handleCart,
        setSaleOrder,
        handleBasket,
        handleCartInsurance,
        handleCartCoupon,
        handleRestartCart,
        handleCurrency,
        handleDeliveryDate,
        handleConfirm,
        toggleCartDrawer
      }}
    >
      {children}
    </CartContext.Provider>
  )
}
