'use client'
import { types } from "@/types"
import { useReducer } from "react"
import { ToastifyContext } from "./ToastifyContext"
import { toastifyReducer } from "./toastifyReducer"

const initialState = {
  show: false,
  error: false,
  msg: null,
  info: false,
}

export const ToastifyProvider = ({ children }) => {
  const [ state, dispatch ] = useReducer(toastifyReducer, initialState)

  const setErrorToastify = props => {
    dispatch({
      type: types.uiSetError,
      payload: props
    })
  }
  const rmvErrorToastify = props => {
    dispatch({
      type: types.uiRemoveError,
      payload: props
    })
  }

  return (
    <ToastifyContext.Provider
      value={{
        ...state,
        setErrorToastify,
        rmvErrorToastify,
      }}
    >
      {children}
    </ToastifyContext.Provider>
  )
}
