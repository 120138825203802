'use client'
import { types } from "@/types"
import { useReducer } from "react"
import { ProductContext, productReducer } from "."

const initialState = {
  more: false,
}

export const ProductProvider = ({ children }) => {
  const [ state, dispatch ] = useReducer(productReducer, initialState)

  const handleInit = props => {
    dispatch({
      type: types.product.init,
      payload: props
    })
  }
  const handleRestart = () => {
    dispatch({ type: types.product.restart })
  }
  const handleMore = () => {
    dispatch({ type: types.product.more })
  }
  return (
    <ProductContext.Provider
      value={{
        prod: { ...state },
        handleInit,
        handleRestart,
        handleMore,
      }}
    >
      {children}
    </ProductContext.Provider>
  )
}
