/* eslint-disable default-param-last */
import { types } from "@/types"
export const menuInitialState = {
  [types.menuExpanded.openMenu]: true,
  [types.menuExpanded.expanded]: 0,
  [types.menuExpanded.expandedCategory]: 0,
  [types.menuExpanded.expandedSubCategory]: 0,
  [types.menuExpanded.level1]: [],
  [types.menuExpanded.level2]: [],
  [types.menuExpanded.level3]: [],
}
export const menuReducer = (state = menuInitialState, action) => {
  switch (action.type) {
  case types.menuExpanded.openMenu:
    return {
      ...state,
      [types.menuExpanded.openMenu]: !state[types.menuExpanded.openMenu],
    }
  case types.menuExpanded.openMenuAuto:
    return {
      ...state,
      [types.menuExpanded.openMenu]: action.payload,
    }
  case types.menuExpanded.expanded:
    return {
      ...state,
      [types.menuExpanded.expanded]: action.payload,
    }
  case types.menuExpanded.expandedCategory:
    return {
      ...state,
      [types.menuExpanded.expandedCategory]: action.payload,
    }
  case types.menuExpanded.expandedSubCategory:
    return {
      ...state,
      [types.menuExpanded.expandedSubCategory]: action.payload,
    }
  case types.menuExpanded.level1:
    return {
      ...state,
      [types.menuExpanded.level1]: action.payload,
      [types.menuExpanded.level2]: [],
      [types.menuExpanded.level3]: [],
    }
  case types.menuExpanded.level2:
    return {
      ...state,
      [types.menuExpanded.level2]: action.payload,
    }
  case types.menuExpanded.level3:
    return {
      ...state,
      [types.menuExpanded.level3]: action.payload,
    }

  default:
    return state
  }
}
