'use client'
import { types } from "@/types"
import { useEffect, useReducer } from "react"
import { ImagesContext, imagesReducer } from "."

const initImages = []

export const ImagesProvider = props => {
  const { children, image } = props
  const [ state, dispatch ] = useReducer(imagesReducer, initImages)

  const handleImages = prop => {
    dispatch({
      type: types.product.img,
      payload: prop
    })
  }
  useEffect(() => {
    handleImages(image)
  }, [image])
  return <ImagesContext.Provider value={state}>{children}</ImagesContext.Provider>
}
