/* eslint-disable default-param-last */

/* eslint-disable no-extra-parens */
/* eslint-disable max-lines */
import { apiCall } from "@/middleware/api"
import { encodeHashJS } from "@/middleware/api/secure"
import { config } from "@/middleware/config/config"
import { types } from "@/types"
import { constants } from "@/utils/global"
import { Base64 } from "js-base64"
import Cookies from "js-cookie"
import { fixTwoDecimal, languageDomainHelper } from ".."

export const recoverSessionCart = async(session, flag = false) => {
  if (flag || session?.data?.user) {
    const encrypt = {
      user_id: flag ? session?.user?.user_id : session?.data?.user?.user_id,
      type: types.web,
    }
    const sendData = {
      url: constants.URL.CART_SESSION,
      encrypt,
      type: `POST`
    }
    const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
    const { error, message } = await apiCall(encriptedData)
    return message.saleOrderId
  }
  return `null`
}

export const transferPayment = async(
  cart, encrypt, router, query
) => {
  const sendData = {
    url: constants.URL.CART_COUPON_ACTION,
    encrypt,
    type: `POST`
  }
  const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
  await apiCall(encriptedData)
  const finalUrl = `${process.env.NEXT_PUBLIC_PAYMENT_OK}?${Object.keys(query).map(key => `${key}=${query[key]}`).
    join(`&`)}`
  await router.push(finalUrl)
}

export const saleOrderSession = async(session, exec) => {
  const tmp = await Cookies.get(config.COOKIES._WLYR_ORDER)
  const cokOrder = tmp ? JSON.parse(tmp)?.saleOrder : undefined
  const sesOrder = await recoverSessionCart(session)
  const order = { saleOrder: `null` }
  switch (true) {
  case cokOrder === undefined && sesOrder === undefined:
    order.saleOrder = `null`
    break
  case (cokOrder === undefined || cokOrder === `null`) && sesOrder !== `null`:
    order.saleOrder = sesOrder
    break
  case cokOrder !== `null` && sesOrder === `null`:
    order.saleOrder = cokOrder
    break
  case !!cokOrder && cokOrder !== `null` && sesOrder !== `null`:
    order.saleOrder = parseInt(cokOrder, 10) < parseInt(sesOrder, 10) ? sesOrder : cokOrder
    break
  default:
    break
  }
  await Cookies.set(config.COOKIES._WLYR_ORDER, JSON.stringify(order), { expires: 30 })
  !!exec && (await exec(order.saleOrder))
}

export const shippingInsuranceCart = async({
  total, address, setInsurance, ...props
}) => {
  const encrypt = {
    total,
    address_id: address,
  }
  const sendData = {
    url: `${constants.URL.CART_SHIP_INSURANCE}`,
    encrypt,
    type: `POST`
  }
  const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
  const { error, message } = await apiCall(encriptedData)
  await setInsurance(message)
  return message
}
export const shippingCost = async({
  total, tariffV, ship, ...props
}) => {
  const encrypt = {
    total,
    address: props.address || `0`,
    country: props.country,
    state: props.state || 0,
    zip: props.zip,
    tariffV,
    ship,
  }

  // Realizamos la llamada apiCall
  const sendData = {
    url: constants.URL.CART_SHIP_COST,
    encrypt,
    type: `POST`
  }
  const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
  const { error, message } = await apiCall(encriptedData)

  return message
}

const calculateCost = async(prop, item, count) => {
  let data = {
    url: constants.URL.CART_CALCULATE_COST,
    encrypt: prop,
    type: `POST`
  }
  let response = { message: {}}
  if (item.pack === undefined) {
    const sendData = {
      url: constants.URL.CART_CALCULATE_COST,
      encrypt: prop,
      type: `POST`
    }
    const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
    response = await apiCall(encriptedData)
  }
  const { message } = await response
  data = {
    line_id: item.line_id,
    variant_id: item.variant_id,
    product_id: prop.product,
    pack_id: item?.pack ? prop.product : null,
    pack_amount: item?.pack ? item.pack.amount || item.packAmount : null,
    pack_group: item?.pack ? item.pack.pack_group : null,
    amount: parseInt(count, 10),
    discount: 0,
    unit_price: message?.cost ? message.cost : item.pack?.price || parseFloat(item.total) / item.pack_amount,
    total: message?.cost ? message.cost : item.pack?.price || parseFloat(item.total) / item.pack_amount,
    flagNeto: false,
    lote: item?.pack?.lote ?? item?.lote
  }
  return data
}

export const recoverSaleOrder = () => {
  const b = Cookies.get(config.COOKIES._WLYR_ORDER)
  let o = null
  if (b) {
    const tmp = JSON.parse(b).saleOrder
    o = tmp === `null` ? null : tmp
  }
  return o
}
export const constructCartLine = async props => {
  const {
    item, unit, objItem, basket, hostname
  } = props
  const lang = hostname.langId
  const lines = []
  if (item !== null) {
    if (item[0]) {
      for (const element of item) {
        const encrypt = {
          discount_tariff: objItem.discount_tariff,
          platform: objItem.platform,
          user_id: objItem.user_id,
          variant_id: element.variant_id,
          product: objItem.product,
        }
        const result = await calculateCost(encrypt, element, unit)
        if (objItem.lineWarr) {
          result.warrext_base = objItem.lineWarr.warrext_base
          result.warrext_id = objItem.lineWarr.warrext_id
          result.warrext_tax = objItem.lineWarr.warrext_tax
          result.warrext_total = objItem.lineWarr.warrext_total
        }
        result.comments = objItem.comments
        lines.push(result)
      }
    } else {
      const encrypt = {
        discount_tariff: objItem.discount_tariff,
        platform: objItem.platform,
        user_id: objItem.user_id,
        variant_id: item.variant_id,
        product: objItem.product
      }
      const result = await calculateCost(encrypt, item, unit)
      if (objItem.lineWarr) {
        result.warrext_base = objItem.lineWarr.warrext_base
        result.warrext_id = objItem.lineWarr.warrext_id
        result.warrext_tax = objItem.lineWarr.warrext_tax
        result.warrext_total = objItem.lineWarr.warrext_total
      }
      result.comments = objItem.comments
      lines.push(result)
    }
  }
  const id = objItem.saleOrder || recoverSaleOrder()
  const shippingAddress = objItem?.address && objItem?.sede === null ? objItem.address : basket?.shipping_address_id && objItem?.sede === null ? basket.shipping_address_id : null
  const shippingService = objItem.ship !== null && objItem?.sede === null ? objItem.ship : null
  const shippingInsurance =

    (objItem?.insurance && objItem?.sede === null) || objItem.insurance === 0 ? objItem.insurance : basket?.shipping_insurance && objItem?.sede === null ? basket.shipping_insurance : null
  const insuranceId =

    (objItem?.insurance_id && objItem?.sede === null) || objItem.insurance_id === 0 ? objItem.insurance_id : basket?.insurance_id && objItem?.sede === null ? basket.insurance_id : null
  const feeAmount = !!objItem.paypal && objItem.paypal !== null ? objItem.paypal.feeAmount : null
  const feePerc = !!objItem.paypal && objItem.paypal !== null ? objItem.paypal.feePerc : null
  const coupon = !!objItem.coupon && objItem.coupon !== null ? objItem.coupon : null

  const pdrId = objItem.pdrId !== null && objItem?.sede === null ? objItem.pdrId : null

  return {
    discount_tariff: objItem.discount_tariff,
    user_id: objItem.user_id,
    language: objItem.language.toLowerCase(),
    sale_order_id: id || null,
    shipping_address_id: shippingAddress,
    shipping_service_id: shippingService,
    shipping_insurance: shippingInsurance,
    insuranceId,
    sede: objItem?.sede ? objItem.sede : null,
    country: objItem?.country ? objItem.country : null,
    type: types.web,
    currency: objItem.currency,
    deliveryDate: objItem.deliveryDate,
    lang,
    lines,
    feeAmount,
    feePerc,
    coupon,
    pdrId,
  }
}
const objBasket = (
  login, hostname, ipInfo, basket, paypal, currency
) => ({
  user_id: login?.user?.user_id || hostname?.user_id,
  saleOrder: recoverSaleOrder(),
  platform: hostname?.id,
  discount_tariff: hostname?.discount_tariff_version,
  country: ipInfo?.code || languageDomainHelper(hostname?.domain),
  language: hostname?.lang,
  paypal: basket?.pay_method?.typeId !== 4 ? null : {
    feeAmount: paypal.feeAmount,
    feePerc: paypal.feePercentage
  },
  coupon: basket?.couponOb || null,
  ship: basket?.shipping_service_id || ipInfo?.ship,
  pdrId: basket?.pdrId || null,
  insurance: basket.shipping_insurance,
  insurance_id: basket.insurance_id,
  currency
})
export const editBasketWeb = async(
  cart, product, variant, unit, login, hostname, ipInfo, sede = null, lineWarr = null, comments = null
) => {
  const {
    basket, paypal, currency, deliveryDate
  } = cart
  const objItem = {
    ...objBasket(
      login, hostname, ipInfo, basket, paypal, currency, deliveryDate
    ),
    sede: basket?.sede || sede,
    product: product.id,
    lineWarr,
    comments
  }

  // if (lineWarr !== null) {
  //   objItem.lineWarr = lineWarr
  // }
  const result = await constructCartLine({
    item: variant,
    unit,
    objItem,
    basket,
    hostname
  })
  return result
}
export const editBasketAddrWeb = async(
  cart, login, hostname, ipInfo, sede = null, address = null
) => {
  const {
    basket, paypal, currency, deliveryDate
  } = cart
  const objItem = {
    ...objBasket(
      login, hostname, ipInfo, basket, paypal, currency, deliveryDate
    ),
    sede,
    address,
    ship: null,
    pdrId: sede !== null ? null : basket?.pdrId || null,
    insurance: 0,
    insurance_id: null,
    coupon: basket.couponOb,
  }
  const result = await constructCartLine({
    item: null,
    unit: 0,
    objItem,
    basket,
    hostname
  })
  return result
}
export const editBasketShipWeb = async(
  cart, login, hostname, ipInfo, ship = null, pdrId = null
) => {
  const {
    basket, paypal, currency, deliveryDate
  } = cart
  const objItem = {
    ...objBasket(
      login, hostname, ipInfo, basket, paypal, currency, deliveryDate
    ),
    ship,
    pdrId,
    address: basket?.shipping_address_id || null,
    sede: basket?.sede_id || null,
    coupon: basket.couponOb,
  }
  const result = await constructCartLine({
    item: null,
    unit: 0,
    objItem,
    basket,
    hostname
  })
  return result
}
export const editBasketInsurance = async(
  cart, login, hostname, ipInfo, ins = null
) => {
  const {
    basket, paypal, currency, deliveryDate
  } = cart
  const objItem = {
    ...objBasket(
      login, hostname, ipInfo, basket, paypal, currency, deliveryDate
    ),
    insurance: ins?.cost || 0,
    insurance_id: ins?.id || null,
    sede: basket?.sede_id || null,
    address: basket?.shipping_address_id || null,
    coupon: basket.couponOb,
  }
  const result = await constructCartLine({
    item: null,
    unit: 0,
    objItem,
    basket,
    hostname
  })
  return result
}
export const editBasketCoupon = async(
  cart, login, hostname, ipInfo, coupon = null, code = null
) => {
  const {
    basket, paypal, currency, deliveryDate
  } = cart
  const objItem = {
    ...objBasket(
      login, hostname, ipInfo, basket, paypal, currency, deliveryDate
    ),
    insurance: basket.shipping_insurance,
    insurance_id: basket.insurance_id,
    sede: basket?.sede_id || null,
    address: basket?.shipping_address_id || null,
    coupon,
    code,
  }
  const result = await constructCartLine({
    item: null,
    unit: 0,
    objItem,
    basket,
    hostname
  })
  return result
}
export const editBasketPayMethod = async(
  cart, login, hostname, ipInfo, payMethod = null, deliveryDate
) => {
  const { basket, paypal, currency } = cart
  const objItem = {
    ...objBasket(
      login, hostname, ipInfo, basket, paypal, currency
    ),
    sede: basket?.sede_id || null,
    address: basket?.shipping_address_id || null,
    paypal: payMethod !== 4 ? null : {
      feeAmount: paypal.feeAmount,
      feePerc: paypal.feePercentage
    },
    deliveryDate
  }
  const result = await constructCartLine({
    item: null,
    unit: 0,
    objItem,
    basket,
    hostname
  })
  return result
}

export const bgContent = async(basket, setBadgeContent) => {
  if (basket?.lines) {
    let temp = 0
    await basket.lines.lines.map(x => {
      temp += x.amount
      return true
    })
    await basket.lines.pack_lines.map(x => {
      temp += x.amount
      return true
    })
    await setBadgeContent && setBadgeContent(temp)
  } else {
    await setBadgeContent && setBadgeContent(0)
  }
}

export const getLinesTotalAmount = (lines, setBadgeContent) => {
  if (lines?.lines?.length || lines?.pack_lines?.length) {
    let temp = lines.lines.reduce((acc, line) => acc + line.amount, 0)
    temp += lines.pack_lines.reduce((acc, line) => acc + line.amount, 0)
    if (setBadgeContent) {
      setBadgeContent(temp)
    }
    return temp
  }
  if (setBadgeContent) {
    setBadgeContent(0)
  }
  return 0
}

export const warrExtPrice = (
  lineTotal, numLines, value, unitId
) => {
  let retVal = 0
  unitId === 8 ? (retVal = numLines * value) : (retVal = (value * lineTotal) / 100)
  return fixTwoDecimal(retVal)
}

export const warrExtCalcTotals = (rate, item, value = null) => {
  const newTotal = value ? parseInt(value, 10) * item.price.cost_iva : item.total
  const extPrice = warrExtPrice(
    newTotal, value || item.amount, item.warranty_extension.value, item.warranty_extension.valUnitId
  )
  const tax = fixTwoDecimal((extPrice * rate) / 100)
  const base = fixTwoDecimal(extPrice - tax)
  return {
    warrext_base: base,
    warrext_tax: tax,
    warrext_id: item.warranty_extension.id,
    warrext_total: extPrice
  }
}

export const verifyPayPalOrder = async props => {
  const { data, setResponse, router } = props
  const encrypt = data
  const sendData = {
    url: constants.URL.PAYPAL_CREATE_ORDER,
    encrypt,
    type: `POST`
  }
  const encriptedData = encodeHashJS(JSON.stringify(sendData), false)

  const { error, message } = await apiCall(encriptedData)

  error && router.push(process.env.NEXT_PUBLIC_PAYMENT_ERROR)
  await setResponse && setResponse(message.data || message)
}
const constructUpdatePayMethod = props => {
  return {
    discount_tariff: props.discount_tariff,
    user_id: props.user_id,
    language: props.language.toLowerCase(),
    lang: props.languageId,
    sale_order_id: props.saleOrder,
    pay_method: props.payMethod,
    payMethodTypeId: props.payMethodType,
    type: types.web,
    currency: props.currency,
  }
}
export const cartUpdatePayMethod = async(
  info, router, flag, transaction, languageId
) => {
  const cartUpdate = await constructUpdatePayMethod(info)
  const encrypt = {
    data: cartUpdate,
  }
  const sendData = {
    url: constants.URL.CART_UPDATE_PAYMETHOD,
    encrypt,
    type: `POST`
  }
  const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
  const { error, message } = await apiCall(encriptedData)
  const query = { o: Base64.btoa(message.id) }
  if (!error) {
    if (flag) {
      switch (message.pay_method?.typeId) {
      case 2:
        await transferPayment(
          message, encrypt, router, query
        )
        break
      case 6:
        const sendData2 = {
          url: constants.URL.MULTIBANCO_CONFIRM,
          encrypt: {
            telephone: ``,
            user_id: info.user_id,
            sale_order_id: message.id,
            transaction_id: transaction,
            prefix: ``,
            pay_method: 33
          },
          type: `POST`
        }
        const encriptedData2 = encodeHashJS(JSON.stringify(sendData2), false)
        const { message: refPay } = await apiCall(encriptedData2)
        query.r = Base64.btoa(refPay.reference)

        await transferPayment(
          message, encrypt, router, query
        )
        break

      default:
        break
      }
    }
  } else {
    // await console.error(message)
    await router.push(process.env.NEXT_PUBLIC_PAYMENT_ERROR)
  }
}

export const fcMethodType = (type, host) => Object.values(host.methodsTypes).find(x => x.typeId === type)?.payMethodId
