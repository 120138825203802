/* eslint-disable default-param-last */
import { CartContext, ToastifyContext } from "@/context"
import { langs } from "@/locale"
import { apiCall } from "@/middleware/api"
import { encodeHashJS } from "@/middleware/api/secure"
import { config } from "@/middleware/config/config"
import { types } from "@/types"
import { constants } from "@/utils/global"
import Cookies from "js-cookie"
import { useSession } from "next-auth/react"
import { useContext } from "react"

export const useCart = hostname => {
  const { setSaleOrder, handleBasket } = useContext(CartContext)
  const { setErrorToastify } = useContext(ToastifyContext)

  const session = useSession()
  const handleBasketId = async(basket, id) => {
    await handleBasket(basket)
    await setSaleOrder(id)
    await Cookies.set(config.COOKIES._WLYR_ORDER, JSON.stringify({ saleOrder: id }), { expires: 30 })
  }
  const addRemoveItem = async props => {
    const {
      data, msg, codeLang, toast = true
    } = props
    const txt = langs[codeLang]
    const sendData = {
      url: constants.URL.CART_ADD_REMOVE,
      encrypt: props,
      type: `POST`
    }
    const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
    const { error, message } = await apiCall(encriptedData)
    if (!error) {
      if (message?.del === undefined) {
        await handleBasketId(message, message?.id)
        toast && await setErrorToastify({
          error,
          message: txt[msg ? types.deleteProduct : message.message],
          info: true
        })
      } else {
        await handleBasketId({}, `null`)
        await addRemoveItem({
          data: {
            ...data,
            sale_order_id: null,
            codeLang
          }
        })
      }
    }
    return {
      error,
      message
    }
  }
  const recoveryCart = async(data, flag = false, setBasket) => {
    const { langId } = hostname
    const encrypt = {
      sale_order_id: data.sale_order_id,
      user_id: data.user_id,
      type: types.web,
      language: data.language?.toLowerCase(),
      discount_tariff: data.discount_tariff,
      lang: langId,
      country: data.country,
    }
    const sendData = {
      url: `${constants.URL.CART_INIT}/${flag}`,
      encrypt,
      type: `POST`
    }
    const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
    const { error, message } = await apiCall(encriptedData)
    if (!error) {
      if (message?.id) {
        if ((!flag || data.sale_order_id !== null) && data.flag === undefined) {
          await setBasket(message)
          return true
        }
      }
    }
  }
  const recoverSessionCart = async() => {
    if (session?.data?.user) {
      const encrypt = {
        user_id: session?.data?.user?.user_id,
        type: types.web,
      }
      const sendData = {
        url: constants.URL.CART_SESSION,
        encrypt,
        type: `POST`
      }
      const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
      const { error, message } = await apiCall(encriptedData)
      await Cookies.set(config.COOKIES._WLYR_ORDER, JSON.stringify({ saleOrder: `${message.saleOrderId}` }), { expires: 30 })
    }
  }
  return {
    addRemoveItem,
    handleBasketId,
    recoveryCart,
    recoverSessionCart
  }
}
