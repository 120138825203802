export const initObj = {
  size: ``,
  toReceive: ``,
  shipCost: 0,
  breadCrumbs: [],
  parentCategory: null,
  categoryName: null,
  category: null,
  comment: {},
  canonical: [],
  similar: [],
  related: [],
  consumable: [],
  ipInfo: {},
}
