import es from "./ES.json"
import de from "./DE.json"
import en from "./EN.json"
import fr from "./FR.json"
import it from "./IT.json"
import pt from "./PT.json"

export const langs = {
  es,
  de,
  en,
  fr,
  it,
  pt,
}
