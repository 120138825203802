'use client'

import { types } from "@/types"
import { useContext, useEffect, useReducer } from "react"
import { CartStepsContext, cartStepsReducer } from "."
import { CartContext } from "../cart/CartContext"
import { initObj } from "./Obj"

export const CartStepsProvider = ({ children, hostname }) => {
  const [ steps, dispatch ] = useReducer(cartStepsReducer, initObj)
  const { cart } = useContext(CartContext)
  const { basket } = cart
  const handleInitSteps = props => {
    dispatch({
      type: types.cartSteps.init,
      payload: props
    })
  }
  const handleRestartSteps = () => {
    dispatch({ type: types.cartSteps.restart })
  }
  const handleAddrList = props => {
    dispatch({
      type: types.cartSteps.addrList,
      payload: props
    })
  }
  const handleShipPickups = props => {
    dispatch({
      type: types.cartSteps.shipPickups,
      payload: props
    })
  }
  const handlePdrsList = props => {
    dispatch({
      type: types.cartSteps.pdrsList,
      payload: props
    })
  }
  const handleDbPdrId = props => {
    dispatch({
      type: types.cartSteps.dbPdrId,
      payload: props
    })
  }
  const handlePdrsListIncludes = props => {
    dispatch({
      type: types.cartSteps.pdrsListIncludes,
      payload: props
    })
  }
  const handleShipServs = props => {
    dispatch({
      type: types.cartSteps.shipServs,
      payload: props
    })
  }
  const handleShipServsNull = () => {
    dispatch({ type: types.cartSteps.shipServsNull })
  }
  const handlePdrsListNotIncludes = () => {
    dispatch({ type: types.cartSteps.pdrsListNotIncludes })
  }
  const handleOpenInvAddr = props => {
    dispatch({
      type: types.cartSteps.openInvAddr,
      payload: props
    })
  }
  const handleOpenBasket = props => {
    dispatch({
      type: types.cartSteps.openBasket,
      payload: props
    })
  }
  const handleSelPickups = props => {
    dispatch({
      type: types.cartSteps.selPickup,
      payload: props
    })
  }
  const handleOpenShipAddr = props => {
    dispatch({
      type: types.cartSteps.openShipAddr,
      payload: props
    })
  }
  const handleOpenShipServs = props => {
    dispatch({
      type: types.cartSteps.openShipServs,
      payload: props
    })
  }
  const handleAddr = props => {
    dispatch({
      type: types.cartSteps.addr,
      payload: props
    })
  }
  const handleSelShipServs = props => {
    dispatch({
      type: types.cartSteps.selShipServs,
      payload: props
    })
  }
  const handleSedeMod = props => {
    dispatch({
      type: types.cartSteps.sedeMod,
      payload: props
    })
  }
  const handlePickupSelected = props => {
    dispatch({
      type: types.cartSteps.pickupSelected,
      payload: props
    })
  }
  const handlePickupModal = props => {
    dispatch({
      type: types.cartSteps.pickupModal,
      payload: props
    })
  }
  const handleInsurance = props => {
    dispatch({
      type: types.cartSteps.insurance,
      payload: props
    })
  }
  const handleOpenPay = props => {
    dispatch({
      type: types.cartSteps.openPay,
      payload: props
    })
  }
  const handleDpm = props => {
    dispatch({
      type: types.cartSteps.dpm,
      payload: props
    })
  }
  const handleType = props => {
    dispatch({
      type: types.cartSteps.type,
      payload: props
    })
  }
  const handleCancel = async props => {
    if (!props) {
      await handleSelShipServs(null)
      await handlePickupSelected(null)
    }
    await handleDbPdrId(null)
    await handlePickupModal(false)
  }
  useEffect(() => {
    if (basket?.id) {
      handleOpenShipAddr(!(basket.shipping_address_id !== null || basket.sede_id !== null))
      basket.shipping_address_id !== null && handleAddr(basket.shipping_address_id)

      basket.sede_id !== null && handleSedeMod({ s: hostname?.sedes.find(x => x.id === basket.sede_id).select, m: hostname?.sedes.find(x => x.id === basket.sede_id).select })
      basket.pay_method_id !== undefined ? handleDpm(basket.pay_method_id !== 1 ? basket.pay_method_id : hostname?.pay_methods?.creditCard) : handleDpm(hostname?.pay_methods?.creditCard)
      basket.payMethodType !== undefined ? handleType(basket.payMethodType !== 1 ? basket.payMethodType : hostname?.payTabs?.creditCard) : handleType(hostname?.payTabs?.creditCard)
    }
  }, [basket])
  return (
    <CartStepsContext.Provider
      value={{
        steps: { ...steps },
        module: {
          handleAddrList,
          handleShipPickups,
          handlePdrsList,
          handlePdrsListIncludes,
          handlePdrsListNotIncludes,
          handleDbPdrId,
          handleShipServs,
          handleShipServsNull,
          handleOpenInvAddr,
          handleSelPickups,
          handleOpenBasket,
          handleOpenShipAddr,
          handleOpenShipServs,
          handleAddr,
          handleSedeMod,
          handleRestartSteps,
          handleSelShipServs,
          handlePickupSelected,
          handlePickupModal,
          handleCancel,
          handleInsurance,
          handleOpenPay,
          handleDpm,
          handleType
        },
      }}
    >
      {children}
    </CartStepsContext.Provider>
  )
}
