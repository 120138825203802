import { apiCall } from "@/middleware/api"
import { encodeHashJS } from "@/middleware/api/secure"
import { languageIDcomplet } from "../functions"
import { constants } from "../global"

export const apiCallRatingProduct = async(props, comment) => {
  const encrypt = {
    productId: props.product,
  }
  if (props.languageId) {
    encrypt.language = props.languageId
  } else {
    const { id } = languageIDcomplet()
    encrypt.language = id
  }
  const sendData = {
    url: constants.URL.PRODUCT_RATING,
    encrypt,
    type: `POST`
  }
  const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
  const { error, message } = await apiCall(encriptedData)
  await comment(message)
  return message
}
