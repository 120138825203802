/* eslint-disable default-param-last */
import { types } from "@/types"
const initialState = {
  more: false,
}
export const productReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.product.init:
    return {
      ...state,
      info: action.payload
    }
  case types.product.restart:
    return initialState
  case types.product.more:
    return {
      ...state,
      more: !state.more
    }

    // case `example`:
    //  return {
    //    ...state,
    //    [action.payload.name]: action.payload.flag,
    //  }

  default:
    return state
  }
}
