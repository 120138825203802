import { apiCall } from "@/middleware/api"
import { encodeHashJS } from "@/middleware/api/secure"
import { constants } from "@/utils/global"
import { payOtherCard } from "../pay-method/payMthod"

export const cartClean = async info => {
  const sendData = {
    url: constants.URL.CART_DELETE,
    encrypt: { data: info },
    type: `POST`
  }
  const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
  const { error, message } = await apiCall(encriptedData)
  return message.delete
}
export const cartPayMethodCard = async(info, router) => {
  const { error, message } = await payOtherCard(info)
  if (!error) {
    router.push(message.redirect)
  } else {
    router.push(`${process.env.NEXT_PUBLIC_PAYMENT_ERROR}?perr=${message?.DS_ERROR_ID ?? message?.errorCode}`)
  }
}
export const cartOrderConfirm = async info => {
  const encrypt = {
    data: info,
  }
  const sendData = {
    url: constants.URL.CART_CONFIRM,
    encrypt,
    type: `POST`
  }
  const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
  const { error, message } = await apiCall(encriptedData)
}

export const handleVisitProductSold = async saleOrderId => {
  const data = {
    type: `sold`,
    saleOrder: saleOrderId,
  }
  const { error, message } = await apiCall({ url: constants.URL.PRODUCT_VISIT, encrypt: data, type: `POST` })
}

export const handleVisitProduct = (basketId, hostname) => {
  const { login, mobile } = hostname
  const sendData = { url: constants.URL.PRODUCT_VISIT, encrypt: {
    type: `update-login`,
    saleOrder: basketId,
    user_id: login?.user?.user_id,
    login: true,
    platformId: hostname.id,
    mobile
  }, type: `POST` }

  const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
  apiCall(encriptedData)
}

export const handleGetAddress = async hostname => {
  const { login } = hostname
  const sendData = { url: constants.URL.ADDRESS_INFO,
    encrypt: { user_id: login?.user?.user_id, language: hostname.lang.toLowerCase() },
    type: `POST` }
  const encriptedData = encodeHashJS(JSON.stringify(sendData), false)

  const { error, message } = await apiCall(encriptedData)
  return message.data ?? message.addrResp
}

export const listPdrs = async(shipPickups, shipId, addr) => {
  if (shipId !== null && shipPickups.length > 0) {
    const sendData = { url: constants.URL.GET_PDRS,
      encrypt: { shipServId: shipId, addrId: addr },
      type: `POST` }
    const encriptedData = encodeHashJS(JSON.stringify(sendData), false)

    const { error, message } = await apiCall(encriptedData)
    return message.data ?? message.addrResp
  }
}

export const getPickups = async() => {
  const sendData = { url: constants.URL.SHIP_PICKUPS,
    encrypt: ``,
    type: `GET` }
  const encriptedData = encodeHashJS(JSON.stringify(sendData), false)

  const { error, message } = await apiCall(encriptedData)
  return message.data ?? message.addrResp
}

export const getCartCoupons = async login => {
  const sendData = { url: constants.URL.CART_COUPONS,
    encrypt: { user_id: login?.user?.user_id },
    type: `POST` }
  const encriptedData = encodeHashJS(JSON.stringify(sendData), false)

  const { error, message } = await apiCall(encriptedData)
  return message.data ?? message.addrResp
}

export const getCartDiscountService = async login => {
  const sendData = { url: constants.URL.CART_DISCOUNT_SERVICE,
    encrypt: { user_id: login?.user?.user_id },
    type: `POST` }
  const encriptedData = encodeHashJS(JSON.stringify(sendData), false)

  const { error, message } = await apiCall(encriptedData)
  return message
}
