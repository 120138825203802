'use client'
import { useEffectOnce } from "@/hooks/utils/useEffectOnce"
import { types } from "@/types"
import { apiCallSizeGuides } from "@/utils/functions/products"
import { apiCallRatingProduct } from "@/utils/rating/ratingHelper"
import { useEffect, useReducer, useState } from "react"
import { PanelLayoutContext, panelLayoutReducer } from "."
import { initObj } from "./Obj"

export const PanelLayoutProvider = props => {
  const {
    children, fields, product, brand, bike, ipInfo
  } = props
  const [ comment, setComment ] = useState({})
  const [ obj, setObj ] = useState()

  const [ size, setSize ] = useState(null)
  const [ state, dispatch ] = useReducer(panelLayoutReducer, initObj)

  const handleInitLayout = prop => {
    dispatch({
      type: types.panelLayout.init,
      payload: prop
    })
  }
  const handleSetComment = prop => {
    dispatch({
      type: types.panelLayout.comment,
      payload: prop
    })
  }

  useEffectOnce(() => {
    (async() => {
      if (!bike) {
        await apiCallRatingProduct({
          product: product.product_id,
          page: 1,
          uid: null
        }, setComment)
        await apiCallSizeGuides({
          category: product.breadcrumbs[0].id,
          brand: brand?.name
        }, setSize)
      }
      const objs = {
        ...initObj,
        breadCrumbs: product.breadcrumbs,
        parentCategory: product.breadcrumbs[0].id,
        categoryName: product.breadcrumbs[1]?.h1 || ``,
        category: product.breadcrumbs[1]?.id || null,
        canonical: !bike && product.relateds !== null ? product.relateds?.canonical ?? [] : [],
        related: !bike && product.relateds !== null ? product.relateds?.related ?? [] : [],
        similar: !bike && product.relateds !== null ? product.relateds?.similar ?? [] : [],
        consumable: !bike && product.relateds !== null ? product.relateds?.consumable ?? [] : [],
        ipInfo,
      }
      await setObj(objs)
    })()
  })
  useEffect(() => {
    !!obj &&
      handleInitLayout({
        ...obj,
        comment,
        size,
      })
  }, [ obj, comment ])

  return (
    <PanelLayoutContext.Provider
      value={{
        productLayout: { ...state },
        handleInitLayout,
        handleSetComment,
      }}
    >
      {children}
    </PanelLayoutContext.Provider>
  )
}
