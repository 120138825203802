/* eslint-disable default-param-last */
import { types } from "@/types"
import { constructProduct } from "@/utils/functions/products"
import { constants } from "@/utils/global"
const initialState = {
  maxPrice: constants.PRICE_RANGE.max,
  result: 0,
  urlResults: 0,
  attr: [],
  catalogue: [],
  brandAttr: [],
  categories: [],
  filters: [],
  labels: [],
}
export const productsReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.products.init:
    return {
      maxPrice: action.payload.maxPrice,
      result: action.payload.result,
      urlResults: action.payload.urlResults,
      attr: action.payload.attr || [],
      catalogue: action.payload.catalogue,
      brandAttr: action.payload.brands || [],
      categories: action.payload.categories || [],
      filters: action.payload.filters || [],
      labels: action.payload.labels || [],
    }
  case types.products.list:
    return {
      ...state,
      maxPrice: action.payload.maxPrice,
      result: action.payload.result,
      urlResults: action.payload.urlResults,
      attr: action.payload.attr || [],
      catalogue: action.payload.catalogue,
      brandAttr: action.payload.brands || [],
      categories: action.payload.categories || [],
      labels: action.payload.labels || [],
    }
  case types.products.restart:
    return initialState

  default:
    return state
  }
}
