/* eslint-disable default-param-last */
import { types } from "@/types"
import { fixTwoDecimal } from "@/utils/functions"
import { initObj } from "./Obj"

export const panelLayoutReducer = (state = initObj, action) => {
  switch (action.type) {
  case types.panelLayout.restart:
    return initObj
  case types.panelLayout.init:
    return {
      ...state,
      ...action.payload,
    }
  case types.panelLayout.comment:
    return {
      ...state,
      comment: action.payload,
    }
  default:
    return state
  }
}
