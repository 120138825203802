/* eslint-disable default-param-last */
import { types } from "@/types"
const initialState = {
  show: false,
  error: false,
  msg: null,
  info: false,
}
export const toastifyReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.uiSetError:
    return {
      show: true,
      error: action.payload.error,
      msg: action.payload.message,
      info: action.payload?.info ? action.payload.info : false,
    }
  case types.uiRemoveError:
    return {
      show: false,
      error: false,
      msg: null,
      info: false,
    }

  default:
    return state
  }
}
