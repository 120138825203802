/* eslint-disable default-param-last */
import { types } from "@/types"
import { initObj } from "./Obj"
export const cartStepsReducer = (state = initObj, action) => {
  switch (action.type) {
  case types.cartSteps.restart:
    return {
      ...initObj,
    }
  case types.cartSteps.init:
    return {
      ...state,
      ...action.payload,
    }
  case types.cartSteps.addrList:
    return {
      ...state,
      addressList: action.payload,
    }
  case types.cartSteps.shipPickups:
    return {
      ...state,
      shipPickups: action.payload,
    }
  case types.cartSteps.pdrsList:
    return {
      ...state,
      pdrsList: action.payload,
    }
  case types.cartSteps.selPickup:
    return {
      ...state,
      selPickup: action.payload,
    }
  case types.cartSteps.pdrsListIncludes:
    return {
      ...state,
      pickupSelected: action.payload.idx,
      selPickup: action.payload.pdr,
    }
  case types.cartSteps.pdrsListNotIncludes:
    return {
      ...state,
      pickupSelected: null,
      selPickup: null,
      dbPdrId: null,
    }
  case types.cartSteps.dbPdrId:
    return {
      ...state,
      dbPdrId: action.payload,
    }
  case types.cartSteps.insurance:
    return {
      ...state,
      insurance: action.payload,
    }
  case types.cartSteps.shipServsNull:
    return {
      ...state,
      pickupSelected: null,
      selPickup: null,
      selShipServs: null,
    }
  case types.cartSteps.shipServs:
    return {
      ...state,
      shipServs: action.payload,
    }
  case types.cartSteps.openInvAddr:
    return {
      ...state,
      openInvAddr: action.payload,
    }
  case types.cartSteps.openBasket:
    return {
      ...state,
      openBasket: action.payload,
    }
  case types.cartSteps.openShipAddr:
    return {
      ...state,
      openShipAddr: action.payload,
    }
  case types.cartSteps.openShipServs:
    return {
      ...state,
      openShipServs: action.payload,
    }
  case types.cartSteps.selShipServs:
    return {
      ...state,
      selShipServs: action.payload,
    }
  case types.cartSteps.pickupSelected:
    return {
      ...state,
      pickupSelected: action.payload,
    }
  case types.cartSteps.pickupModal:
    return {
      ...state,
      openPickUps: action.payload,
    }
  case types.cartSteps.addr:
    return {
      ...state,
      address: action.payload,
      sede: action.payload === null ? state.sede : null,
      model: action.payload === null ? state.model : null,
    }
  case types.cartSteps.sedeMod:
    return {
      ...state,
      address: null,
      sede: action.payload.s,
      model: action.payload.m,
    }
  case types.cartSteps.openPay:
    return {
      ...state,
      openPay: action.payload,
    }
  case types.cartSteps.dpm:
    return {
      ...state,
      dpm: action.payload,
      payMethod: action.payload,
    }
  case types.cartSteps.type:
    return {
      ...state,
      type: action.payload,
    }
  default:
    return state
  }
}
