/* eslint-disable default-param-last */
import { types } from "@/types"
const basket = {
  basket: false,
  menu: false,
}
export const basketReducer = (state = basket, action) => {
  switch (action.type) {
  case types.basket.init:
    return {
      basket: false,
      menu: false,
    }
  case types.basket.showBasket:
    return {
      basket: action.payload,
      menu: false,
    }

  case types.basket.showMenu:
    return {
      basket: false,
      menu: action.payload,
    }
  default:
    return state
  }
}
